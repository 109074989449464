<template>
  <div>
    <!-- 页头搜索 -->
    <div class="input">
      <div class="input-font">
        <div class="fontdiv">角色昵称</div>
        <el-input
          placeholder="请输入角色名称"
          v-model="searchForm.roleName"
          clearable
          size="medium"
        ></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">状态</div>
        <el-select
          v-model="searchForm.status"
          placeholder="请选择状态"
          clearable
          class="select-width"
          size="medium"
        >
          <el-option label="启用" value="0" />
          <el-option label="禁用" value="1" />
        </el-select>
      </div>
      <el-button
        class="button-height"
        type="primary"
        icon="el-icon-search"
        :loading="false"
        @click="getTableData()"
        >搜索
      </el-button>
      <el-button
        class="button-height"
        plain
        icon="el-icon-refresh"
        @click="resetSearch()"
        >重置</el-button
      >
    </div>

    <!-- 页头按钮 -->
    <el-row>
      <el-button
        size="medium"
        type="primary"
        plain
        icon="el-icon-download"
        :disabled="false"
        @click="add()"
        >添加</el-button
      >
      <el-button
        size="medium"
        type="danger"
        plain
        icon="el-icon-download"
        :disabled="false"
        @click="deleteList()"
        >批量删除</el-button
      >
    </el-row>

    <!-- 列表渲染 -->
    <div class="table">
      <el-table
        :data="tableData"
        ref="multipleTable"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" min-width="60"></el-table-column>
        <el-table-column
          type="index"
          label="序号"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="roleName"
          label="角色名称"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="roleKey"
          label="权限字符"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          min-width="160"
        ></el-table-column>
        <el-table-column
          prop="updateTime"
          label="更新时间"
          min-width="160"
        ></el-table-column>
        <el-table-column prop="roleSort" label="排序" min-width="80">
          <template #default="scope">
            <el-tag>{{ scope.row.roleSort }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="90">
          <template #default="scope">
            <div @click="changeStatus(scope.row)">
              <el-switch
                v-model="scope.row.status"
                :active-value="'0'"
                :inactive-value="'1'"
              ></el-switch>
            </div>
          </template>
        </el-table-column>
        <!-- 浮动侧边栏 -->
        <el-table-column
          label="操作"
          fixed="right"
          min-width="130"
          align="center"
        >
          <template #default="scope">
            <el-link
              class="marginRight"
              @click="editRow(scope.row)"
              type="primary"
              :underline="false"
              >编辑</el-link
            >
            <el-link
              @click="deleteRow(scope.row.mchNo)"
              type="danger"
              :underline="false"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <Pagination
      :total="total"
      :page="pageNumber"
      :size="pageSize"
      @getPage="getPage($event)"
      @getSize="getSize($event)"
    >
    </Pagination>

    <!-- 修改 -->
    <el-drawer
      title="修改"
      :visible.sync="updateFormVisible"
      direction="rtl"
      size="30%"
    >
      <div class="drawer-content">
        <el-form
          :model="updateForm"
          :rules="rules"
          ref="updateForm"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="角色昵称" prop="roleName">
            <el-input
              v-model="updateForm.roleName"
              placeholder="请输入角色昵称"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色权限字符" prop="roleKey">
            <el-input
              v-model="updateForm.roleKey"
              placeholder="请输入角色权限字符"
            ></el-input>
          </el-form-item>
          <el-form-item label="数据范围" prop="dataScope">
            <el-select v-model="updateForm.dataScope" placeholder="请选择">
              <el-option label="仅本人" value="1"></el-option>
              <el-option label="全部数据权限" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="显示顺序" prop="roleSort">
            <el-input
              v-model="updateForm.roleSort"
              placeholder="请输入角色显示顺序"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="updateForm.remark"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <a-button class="marginRight" @click="updateFormVisible = false">
            取消
          </a-button>
          <a-button class="marginRight" @click="resetForm"> 重置 </a-button>
          <a-button type="primary" @click="editSubmit"> 保存 </a-button>
        </div>
      </div>
    </el-drawer>

    <!-- 添加 -->
    <el-drawer
      title="添加"
      :visible.sync="addFormVisible"
      direction="rtl"
      size="30%"
    >
      <div class="drawer-content">
        <el-form
          :model="addForm"
          :rules="rules"
          ref="addForm"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="角色昵称" prop="roleName">
            <el-input
              v-model="addForm.roleName"
              placeholder="请输入角色昵称"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色权限字符" prop="roleKey">
            <el-input
              v-model="addForm.roleKey"
              placeholder="请输入角色权限字符"
            ></el-input>
          </el-form-item>
          <el-form-item label="数据范围" prop="dataScope">
            <el-select v-model="addForm.dataScope" placeholder="请选择">
              <el-option label="仅本人" value="1"></el-option>
              <el-option label="全部数据权限" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="显示顺序" prop="roleSort">
            <el-input
              v-model="addForm.roleSort"
              placeholder="请输入角色显示顺序"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="addForm.remark"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <a-button class="marginRight" @click="addFormVisible = false">
            取消
          </a-button>
          <a-button class="marginRight" @click="resetForm"> 重置 </a-button>
          <a-button type="primary" @click="addSubmit"> 确认 </a-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getAllData,
  deletesRoleInfo,
  updateRoleInfo,
  addRoleInfo,
} from "@/api/role";

import Pagination from "@/components/Pagination";
export default {
  name: "roleManage",
  components: { Pagination },

  data() {
    return {
      //是否展示路由
      isShow: false,
      //页头搜索数据
      searchForm: {},
      //保存多选框选中的行数据
      selectedRows: [],
      //渲染列表数据
      tableData: [],
      //总条数
      total: 0,
      //当前页数
      pageNumber: 1,
      //当前每页条数
      pageSize: 5,
      //修改 是否展示
      updateFormVisible: false,
      //修改表单数据
      updateForm: {},
      //添加 是否展示
      addFormVisible: false,
      //连接数据对话框表单数据
      addForm: {},
      //连接数据对话框表单数据规则
      rules: {
        rolename: [
          { required: true, message: "请输入角色昵称", trigger: "blur" },
        ],
        roleKey: [
          { required: true, message: "请输入角色权限字符", trigger: "blur" },
        ],
        dataScope: [
          { required: true, message: "请选择数据范围", trigger: "blur" },
        ],
        roleSort: [
          { required: true, message: "请输入角色显示顺序", trigger: "blur" },
        ],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
    };
  },

  mounted() {
    this.getTableData();
  },

  methods: {
    // 分页查询
    async getTableData() {
      const page = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      const data = this.searchForm;
      const res = await getAllData(page, data);
      this.tableData = res.data.rows;
      this.total = parseInt(res.data.total);
    },

    // 页头搜索
    resetSearch() {
      this.searchForm = {};
    },

    // 页头添加按钮
    add() {
      this.addFormVisible = true;
    },

    // 页头添加按钮--确定按钮
    async addSubmit() {
      const res = await addRoleInfo(this.addForm);
      this.addFormVisible = false;
      this.$message.success("添加成功");
      this.getTableData();
    },

    //通过选中的多行数据存入数组
    handleSelectionChange(selection) {
      this.selectedRows = selection;
      console.log(this.selectedRows);
    },

    // 页头删除按钮（多行）
    async deleteList() {
      const data = this.selectedRows;
      const res = await deletesRoleInfo(data);
      this.$message.success("删除成功");
      this.getTableData();
    },

    // 修改状态
    async changeStatus(row) {
      console.log(row.status);
      var text = "操作成功";
      if (row.status == 0) {
        text = "启用成功";
      } else if (row.status == 1) {
        text = "禁用成功";
      }
      const res = await updateRoleInfo(row);
      this.$message.success(text);
    },

    // 侧边栏修改按钮
    editRow(row) {
      this.updateForm = row;
      this.updateFormVisible = true;
    },

    // 侧边栏修改按钮--保存按钮
    async editSubmit() {
      const res = await updateRoleInfo(this.updateForm);
      this.updateFormVisible = false;
      this.$message.success("修改成功");
      this.getTableData();
    },

    // 删除
    deleteRow(roleId) {
      this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = [
            {
              roleId: roleId,
            },
          ];
          const res = await deletesRoleInfo(data);
          this.getTableData();
          this.$message.success("删除成功");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 表单重置
    resetForm() {
      this.addForm = {};
      this.updateForm = {};
    },

    //获取当前页数
    getPage(value) {
      this.pageNumber = value;
      this.getTableData();
    },

    //获取每页多少条
    getSize(value) {
      this.pageSize = value;
      this.getTableData();
    },
  },
};
</script>

<style scoped lang="less">
/* 搜索框样式 */
.input {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;

  .input-font {
    width: 400px;
    display: flex;
    margin-bottom: 10px;

    .fontdiv {
      color: #999;
      font-weight: 600;
      font-size: 15px;
      width: 150px;
      line-height: 36px;
      text-align: center;
    }

    .select-width {
      width: 400px;
    }
  }

  .button-height {
    height: 36px;
    margin-bottom: 10px;
    margin-left: 15px;
  }
}

/* 表格 */
.table {
  margin-top: 15px;

  .marginRight {
    margin-right: 20px;
  }

  :deep .el-table .el-table__header-wrapper .el-table__header .el-table__cell {
    font-size: 14px;
    color: #999;
    font-weight: 600px;
    text-align: center;
    background-color: #f2f2f2;
  }

  :deep .el-table th.el-table__cell.is-leaf {
    background-color: #f2f2f2 !important;
  }

  :deep
    .el-table
    .el-table__body-wrapper
    .el-table__body
    .el-table__row
    .el-table__cell
    .cell {
    text-overflow: clip;
    text-align: center;
  }
}

/* 抽屉内容 */
.drawer-content {
  padding: 0 30px 70px 20px;

  .drawer-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
    z-index: 1;
  }

  .marginRight {
    margin-right: 8px;
  }
}
</style>
